import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import {Container, Row, Col, Card} from "react-bootstrap"

class Contact extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="Contact at Capernwray" />
        <Container>
          <Row>
            <Col sm={12}>
              <h1 className="page-title my-4">Contact</h1>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <h2>
              If you would like to find out more about Capernwray Diving
              Centre please do not hesitate to get in touch.
              </h2>
              <div className="d-flex justify-content-start">
                <a
                  href="https://cprnwry.typeform.com/to/wrVvGc"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn btn-primary my-4 mx-2"
                >
                  Email: info@dive-site.co.uk
                </a>
                <a
                  href="tel:01524735132"
                  className="btn btn-primary my-4 mx-2"
                >
                  Phone: 01524735132
                </a>
                
              </div>
              <Card>
                <Card.Header className="d-flex justify-content-between">
                  <h2>Find us</h2>
                  <form
                  className="d-flex"
                  action="https://maps.google.com/maps"
                  method="get"
                  target="_blank"
                >
                  <input type="text" name="saddr" />
                  <input
                    type="hidden"
                    name="daddr"
                    value="Jackdaw Quarry, Capernwray Rd, Over Kellet, Carnforth LA6 1AD"
                    placegolder="Your location"
                  />
                  <input
                    className="btn btn-primary ms-2"
                    type="submit"
                    value="Get directions"
                  />
                </form>
                </Card.Header>
                <Card.Body>
                  <iframe
                    title="Capernwray Diving Centre - Google Maps"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2337.4509595018762!2d-2.7261007841315976!3d54.136656980152146!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487c835d096db001%3A0xd03748c37867d4ca!2sCapernwray+Diving+Centre!5e0!3m2!1sen!2suk!4v1558094377688!5m2!1sen!2suk"
                    width="100%"
                    height="450"
                    frameBorder="0"
                    allowFullScreen
                  />
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </Layout>
    )
  }
}

export default Contact

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
